.post-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
}

.post-content-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: max-content;
}

.post-title {
  border-bottom: 2px solid black;
  /* margin-bottom: 1rem; */
}

.post-description {
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
}

.post-dialog-container {
  padding: 1rem;
  width: 50rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.post-dialog-container-mobile {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.post-dialog-upload-image {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.post-dialog-tags-list {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 1rem 0rem;
}

.post-dialog-bottom-view {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-end;
}
.post-dialog-bottom-view-mobile {
  display: flex;
  flex-direction: column-reverse;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 2rem;
}
