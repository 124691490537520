.login-dialog-container {
  padding: 1rem;
  width: 30rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.login-dialog-container-mobile {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
