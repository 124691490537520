.button-outlined {
  border: none;
  background-color: transparent;
  border: 1px solid black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  text-transform: uppercase;
}

.button-outlined:hover {
  background-color: grey;
}

.button-outlined:active {
  background-color: aliceblue;
}

.toolbar-desktop {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.text-field-toolbar-background {
  background-color: white;
  border-radius: 4px;
  flex-shrink: 2;
}

.text-field-toolbar-background-mobile {
  background-color: white;
  border-radius: 4px;
  flex-shrink: 2;
  margin: 0.5rem 0rem 0.5rem 0rem;
  width: 50%;
}

.row {
  display: flex;
  gap: 1rem;
}

.row-mobile {
  display: flex;
  gap: 0.5rem;
  align-content: center;
}
